.ss-modal {
  .modal-content {
    border-radius: 4.8px;
    border: none !important;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);

    .modal-header {
      .modal-title {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
      }
    }
    .modal-footer > * {
      margin: 0;
    }
    .modal-footer {
      .button-container:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
