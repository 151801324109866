.ss-select {
  width: auto;

  .Select-control {
    border: 1px solid $gray-400;
    border-radius: 4px;
  }
  .Select-placeholder {
    color: $gray-600;
    padding: 0;
  }
}
.dropdown-with-icon {
  button {
    background-color: transparent !important;
    border: none;
    padding: 0;
    padding-bottom: 4px;
    box-shadow: none !important;
  }
  .dropdown-toggle::after {
    display: none;
  }
}
.ss-select-multi {
  height: 30px;
  margin-top: 4px;
  .Select-placeholder {
    color: $gray-400;
    font-weight: 400;
    padding: 0;
    font-size: 14px;
    border-radius: 2px;
    border-color: $gray-200;
  }
  span {
    font-weight: 400;
  }
}
.multi-select-widthauto .Select-menu-outer {
  z-index: 1057 !important;
  width: auto !important;
  font-size: 11px !important;
  padding: 6px 6px 4px 2px;
}

.multi-select-width100 .Select-menu-outer {
  width: 100% !important;
  font-size: 11px !important;
  padding-top: 5px;
}

.multi-select-control .Select-menu-outer {
  width: 100%;
  font-size: 11px;
  padding-top: 0.3rem;
}
.custom-multiselect-status {
  margin-left: 3px;
  vertical-align: baseline;
  position: relative;
  bottom: 3px;
}
.multi-select-widthauto .Select-menu-outer {
  z-index: 999 !important;
  width: auto !important;
  font-size: 11px !important;
}

.multi-select-width100 .Select-menu-outer {
  width: 100% !important;
  font-size: 11px !important;
  min-width: 100px !important;
}
.filter-dropdown-wrapper {
  width: unset;
  margin-left: 12px;
  width: 38px;
  .icon-toogle {
    width: 38px;
    height: 38px;
    border: 1px solid $gray-500 !important;
  }
}
.nested-dropdown {
  .dropdown-toggle {
    border: none !important;
    box-shadow: none !important;
    color: $body-black !important;
    padding: 4px 16px;
    &::before {
      display: none !important;
    }
  }
}
