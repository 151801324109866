.ss-table-container {
  .react-bs-container-body {
    min-height: 200px;
    max-height: calc(100vh - 355px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .caret-position {
    margin-bottom: 4px;
    margin-left: 4px;
  }
  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 10;
  }
  table {
    margin-bottom: 0;
    table-layout: fixed;
    th,
    td {
      padding: 0.5rem;
    }
  }
  .table thead {
    tr {
      border-top: hidden;
    }
    th {
      border-bottom: 2px solid $gray-900;
      font-size: 16px;
      font-weight: 700;
      vertical-align: middle;
      input {
        margin-top: 4px;
        padding: 4px 9px 3px 9px;
        border-color: $gray-200;
        font-size: 14px;
        line-height: 150%;
        border-radius: 2px;
        &::placeholder {
          color: $gray-400;
        }
      }
    }
  }
  .inline-actions-wrapper {
    display: flex;
    align-items: center;
  }
  .expanded-body {
    & > td > div {
      background-color: $sapphire-100;
      margin-left: 50px;
      padding-left: 19px;
    }
    &:hover {
      & > td {
        background-color: $sapphire-100 !important;
      }
    }
  }
  .expanded-table {
    .react-bs-table-tool-bar {
      .row {
        & > :first-child {
          display: none;
        }
      }
      .form-group {
        margin-top: 15px;
        margin-bottom: 17px;
      }
    }
  }
  .react-bs-table-expand-cell {
    cursor: pointer;
  }

  .table-action-container {
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      cursor: pointer;
      padding: 0 10px;
      position: relative;
      color: $Theme-Navy;

      svg {
        margin-top: -2px;
      }
    }

    span:not(:last-child)::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 90%;
      right: 0;
      top: 1px;
      border-radius: 20px;
      background-color: $gray-300;
    }
  }
}
