@import "branding";

body {
  font-family: $font-family;
  color: $body-black;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  margin-bottom: 0 !important;
}

#main {
  padding-top: 20px;
  padding-left: 22px;
  padding-right: 32px;
}

.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

.content-wrapper {
  padding: 16px 23px 22px 24px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

button {
  cursor: pointer;
}

hr {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
}

.page-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 32px !important;
}

.page-subtitle {
  font-size: 18px;
  font-weight: 600;
  color: $Theme-Sapphire;
  font-style: normal;
  line-height: 120%;
  margin-bottom: 10px !important;
}

.page-description {
  color: $gray-600;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: $Theme-Sapphire;
}
