@import './varibles.scss';

@mixin transform($property) {
    -webkit-transform: $property;
    -ms-transform: $property;
    transform: $property;
}

@mixin display-flex($direction: row){
    display: flex;
    flex-direction: $direction;
    align-items: center;
    justify-content: center;
}

@mixin bordered($color: $gray-400, $width: 1px) {
    border: $width solid $color;
}

@mixin box-sizing{
    box-sizing: border-box;
}

@mixin full-width-full-height{
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
@mixin ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@mixin word-wrap{
    word-wrap: break-word;
    word-break: break-all;
}