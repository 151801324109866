.feature-disabled-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 108px);
  width: 100%;
}

.feature-disabled-body h5 {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  margin-top: 40px;
  color: #212529;
}

.feature-disabled-body-text {
  font-size: 20px;
  font-weight: 400;
  white-space: pre;
  color: #212529;
  line-height: 28px;
  text-align: center;
}

.feature-disabled-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
