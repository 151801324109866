@import "../../../styles/mixins.scss";
@import "../../../styles/branding.scss";

.ss-btn {
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    cursor: not-allowed;
    background-color: $gray-100;
    color: $gray-200;
    border-color: $gray-200;
    text-decoration: none;
  }
}

.ss-btn-primary {
  border: 1px solid $Theme-Sapphire;
  background-color: $Theme-Sapphire;
  color: $white;

  &:hover,
  &:focus,
  &:active {
    background-color: $sapphire-700 !important;
    text-decoration: underline;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    svg > path {
      fill: $gray-200;
    }
  }
}

.ss-btn-outline-primary {
  border: 1px solid $Theme-Sapphire;
  background-color: $white;
  color: $Theme-Sapphire;

  &:hover,
  &:focus {
    background-color: $Theme-Sapphire;
    text-decoration: underline;

    svg > path {
      fill: $white;
    }
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    background-color: $white;
  }

  svg > path {
    fill: $Theme-Sapphire;
  }
}

.ss-btn-secondary {
  border: 1px solid $Theme-Navy;
  background-color: $Theme-Navy;
  color: $white;

  &:hover,
  &:focus {
    background-color: $navy-700;
    text-decoration: underline;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    background-color: $navy-200;
    color: $gray-400;
    border-color: $navy-200;
    text-decoration: none;

    svg > path {
      fill: $gray-400;
    }
  }
}

.ss-btn-outline-secondary {
  border: 1px solid $gray-500;
  background-color: $white;
  color: $black;

  &:hover,
  &:focus {
    border: 1px solid $gray-500;
    color: $black;
    background-color: $gray;

    svg > path {
      fill: $black;
    }
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    background-color: $white;

    svg > path {
      fill: $gray-200;
    }
  }

  svg > path {
    fill: $black;
  }
}

.ss-btn-link {
  background-color: $white;
  border: none;
  color: $Theme-Navy;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
    background-color: $white !important;
    color: $Theme-Sapphire !important;
    border: none !important;

    svg > path {
      fill: $Theme-Sapphire;
    }
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    background-color: $white;
    color: $gray-800;
    text-decoration: none;

    svg > path {
      fill: $gray-800;
    }
  }

  svg > path {
    fill: $Theme-Navy;
  }
}

.ss-btn-danger {
  border: 1px solid $error;
  background-color: $error;
  color: $white;

  &:hover,
  &:focus,
  &:active {
    border-color: $error-700 !important;
    background-color: $error-700 !important;
    text-decoration: underline;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    color: $white;
    background-color: $error-200;
    border-color: $error-200;
    text-decoration: none;
  }
}

.ss-btn-success {
  border: 1px solid $success;
  background-color: $success;
  color: $white;

  &:hover,
  &:focus {
    border-color: $success-700;
    background-color: $success-700;
    text-decoration: underline;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    color: $white;
    background-color: $success-200;
    border-color: $success-200;
    text-decoration: none;
  }
}

.ss-btn-light {
  border: 1px solid $gray-400;
  background-color: $gray;
  color: $body-black;

  &:hover,
  &:focus,
  &:active {
    border-color: $gray-700 !important;
    background-color: $gray-700 !important;
    text-decoration: underline;
    color: $white;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    color: $white;
    background-color: $gray-200;
    border-color: $gray-200;
    text-decoration: none;
  }
}

.icon-only {
  width: 38px;
  height: 38px;
  border-radius: 2px;
  border: 1px solid $Theme-Sapphire;
  background-color: $white !important;
  padding: 5px 5px;

  &:hover,
  &:focus,
  &:active {
    background-color: $sapphire-700 !important;
    text-decoration: none;
    color: $white !important;

    svg > path {
      fill: $white;
    }
  }

  &:disabled {
    background-color: $gray-100;
    border-color: $gray-100;
  }
}

.icon-left {
  @include display-flex(row);

  svg {
    margin-right: 8px;
  }
}

.icon-right {
  @include display-flex(row-reverse);

  svg {
    margin-left: 8px;
  }
}

.icon-top {
  @include display-flex(column);

  svg {
    margin-bottom: 8px;
  }
}

.icon-bottom {
  @include display-flex(column-reverse);

  svg {
    margin-top: 8px;
  }
}

.icon-center {
  @include display-flex(row);
}
