@import "./varibles.scss";

$font-family: "Mulish", sans-serif;

$light: #f8f9fa;
$dark: #212529;
$white: #ffffff;
$black: #000000;

/** Bootstrap theme variable overrides*/
$theme-colors: (
  "light": $light,
  "dark": $dark,
  "primary": $sapphire,
  "secondary": $navy,
  "info": $info,
  "success": $success,
  "warning": $warning,
  "danger": $error,
  "sapphire": $sapphire
);
$border-radius: 4px;
$body-black: #212529;
$Theme-Sapphire: #0973ba;
$Theme-Navy: #05386b;
$Theme-Border: #dee2e6;

// FONT
$font-sm: 12px;
$font-lg: 16px;
$base-font-size: 12px;
$font-size-md: 14px;
