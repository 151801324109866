@import "../../../styles/branding.scss";

.my-profile-modal {
  .modal-dialog {
    max-width: 933px;
  }

  .modal-body {
    padding: 0;
  }
}
#profile-form {
  .react-tel-input.error .flag-dropdown {
    border: 1px solid $error;
  }

  .flag-dropdown {
    border-radius: 5px 0 0 5px;
    background-color: $white;
  }

  label {
    font-size: $font-size-md;
  }

  .react-tel-input .selected-flag .arrow {
    height: 6px;
    width: 6px;
    border: solid $black;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    top: 2px;
    left: 26px;
  }

  .react-tel-input .form-control {
    padding-left: 58px;
  }

  .react-tel-input .selected-flag {
    width: 50px;
  }
}

.my-account {
  height: 100%;

  .side-bar {
    background-color: $gray;
    font-size: $font-lg;

    .nav-link {
      border-left: 3px solid transparent;
      color: $black;
      font-weight: 400;
      padding-left: 13px;

      &.active {
        background-color: $white;
        border-left: 3px solid $success;
        font-weight: 700;
      }
    }
  }

  .tab-content {
    padding: 20px 0;
  }

  .tab-pane {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 15px;

    & > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    #profile-form {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .verification-container {
    border-radius: 4px;
    padding: 10px 20px;
    border: 1px solid $gray;
    border-left: 4px solid $info-500;
    .verification-description {
      font-size: 16px;
    }

    .input-wrapper {
      display: flex;
      line-height: 1;
      button {
        background-color: #6b7075;
      }
    }
    button {
      font-size: 14px;
    }

    .form-control {
      width: 140px;
      margin-right: 10px;
    }
  }
}
