.settings-accordion {
  .accordion-header {
    button {
      padding: 6px 12px;
      background-color: unset;
      box-shadow: none;
      color: $Theme-Sapphire !important;
      padding-left: 0;
    }
  }
  .accordion-body {
    padding: 0;
  }
}
