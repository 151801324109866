.title-h3 {
  font-size: 20px;
}

.title-h4 {
  font-size: 18px;
}

.heading-blue-1 {
  color: #0973ba;
  font-size: 20px;
  font-weight: 500;
}

.heading-blue-2 {
  color: #0973ba;
  font-size: 14px;
  font-weight: 600;
  line-height: 125%;
}

.text-gray-description {
  color: #565a5e;
  font-size: 14px;
}
