.error-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: first baseline;
  align-items: center;
}

.error-message {
  font-size: 24px;
  font-weight: 500;
  padding: 20px 10px;
}

.retry-button {
  border: none;
  color: #fff;
  background-color: #13294b;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}
