#ssuite-layout-wrapper .side-menu-container .sidebar .group-menu-container {
  padding: 10px 0px;
}

#ssuite-layout-wrapper .side-menu-container .sidebar .group-menu-container .accordion-item .accordion-button:focus {
  outline: none !important;
}

#ssuite-layout-wrapper .side-menu-container .sidebar .group-menu-container:not(:first-child) {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: rgba(255, 255, 255, 0.25) !important;
  margin-top: 0px;
}

#ssuite-layout-wrapper > header .profile-dropdown-wrapper .dropdown-toggle {
  padding: 0px 15px !important;
}

#ssuite-layout-wrapper > header .profile-dropdown-wrapper .dropdown-menu .dropdown-header {
  font-weight: 600 !important;
}

#ssuite-layout-wrapper > header .profile-dropdown-wrapper .dropdown-menu .dropdown-item:not(:last-child) {
  font-weight: 400 !important;
}

#ssuite-layout-wrapper > header .profile-dropdown-wrapper .dropdown-menu .dropdown-item:last-child {
  font-weight: 600 !important;
}

.menu-accordion-header-div span {
  padding-bottom: 3px;
}

#ssuite-layout-wrapper .side-menu-container .sidebar .group-menu-container .accordion-disabled-item .accordion-button {
  cursor: not-allowed;
}

#ssuite-layout-wrapper
  .side-menu-container
  .sidebar
  .group-menu-container
  .accordion-disabled-item
  .accordion-button.collapsed::after {
  opacity: 0.5;
}

#ssuite-layout-wrapper > header .right-child {
  margin-right: 0px !important;
}

#ssuite-layout-wrapper > header .right-child .widget-wrapper {
  margin-right: 0px !important;
  margin-top: -6px;
}

#ssuite-layout-wrapper .header-actions-wrapper {
  margin-right: 17px;
  margin-top: -3px;
  cursor: pointer;
}

.bootstrap-grid-no-padding {
  margin-right: 15px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.left-child svg {
  width: 120px;
  height: 120px;
}

.react-bs-table-no-data {
  text-align: center;
}
