@import "./varibles.scss";

hr {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: $sapphire;
}

.form-group {
  margin-bottom: 16px;
}

.form-label {
  color: $text-color;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 2px;
}

.form-label[aria-required="true"]::after {
  content: " *";
  font-size: 14px;
  color: $error;
}

.form-control {
  border-color: $gray-400;
  border-radius: $border-radius;

  &::placeholder {
    color: $gray-600;
  }
}

.ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.required {
  color: $error;
}

.background-highlight {
  animation: bgHighlightAnimation 2s forwards;
}

@keyframes bgHighlightAnimation {
  from {
    background-color: $navy-200; /* Change this to the color you want */
  }
  to {
    background-color: transparent;
  }
}
