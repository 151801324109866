.form-label {
  margin: 0;
  margin-bottom: 0;
}
.form-check-input {
  margin-left: 0;
  margin-right: 0;
  position: static !important;
  border-color: darkgrey;
}
.form-check-input:checked {
  background-color: $Theme-Sapphire !important;
  border-color: $Theme-Sapphire !important;
}
.form-check-input:focus {
  box-shadow: none !important;
  border-color: $Theme-Sapphire !important;
}

.ss-input {
  border: 1px solid $gray-400 !important;
  padding: 5px 9px !important;
  &::placeholder {
    color: $gray-400 !important;
  }
  &:focus {
    box-shadow: 0px 0px 0px 2px rgba(13, 110, 253, 0.25) !important;
    border: 1px solid #86b7fe !important;
  }
}
.ss-input-invalid {
  border-color: $error;
  box-shadow: 0px 0px 0px 2px rgba(220, 53, 69, 0.5) !important;
  border: 1px solid #cc4a43 !important;
}
.text-danger {
  color: $error !important;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  bottom: -30%;
}
.edit-client-info-modal {
  .modal-body{
    padding: 22px 24px;
  }
  .form-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  .optional-label {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    color: $gray-500;
    font-weight: 400 !important;
  }
  .required-marker {
    position: relative;
    &-before {
      &:before {
        content: "*";
        width: 4px;
        height: 4px;
        position: absolute;
        top: 0;
        left: -8px;
        color: $error;
      }
    }
    &-after {
      &:after {
        content: "*";
        width: 4px;
        height: 4px;
        position: absolute;
        top: 0;
        right: -8px;
        color: $error;
      }
    }
  }
}
