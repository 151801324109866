@import "../../../styles/branding.scss";

.toaster-container {
  width: 400px !important;
  margin-right: 9px;
  margin-top: 47px;

  .toaster-body {
    border-radius: 4px;
    min-height: 56px;
    color: $black;

    [aria-label="close"] {
      display: none;
    }
  }

  .toaster-error {
    background: $error-100;
    border-left: 5px solid $error;
  }

  .toaster-warning {
    background: $warning-100;
    border-left: 5px solid $warning;
  }

  .toaster-success {
    background: $success-100;
    border-left: 5px solid $success-500;
  }

  .toaster-info {
    background: $sapphire-100;
    border-left: 5px solid $sapphire;
  }

  .toaster-icon {
    align-self: center;
    padding-right: 12px;
  }

  .toaster-icon.toaster-close svg:hover {
    fill: $gray-400;
  }

  .toaster-text {
    font-size: 16px;
    padding-right: 14px;
    font-family: $font-family;
  }
}
